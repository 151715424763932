import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/CountrySelection.vue"),
  },

  {
    path: "/videos",
    name: "Videos",
    component: () =>
      import(/* webpackChunkName: "Videos" */ "../views/Videos.vue"),
  },

  {
    path: "/videos/preview",
    name: "Preview",
    component: () =>
      import(/* webpackChunkName: "Preview" */ "../views/Preview.vue"),
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: () =>
      import(/* webpackChunkName: "Subscribe" */ "../views/Subscribe.vue"),
  },

  {
    path: "/auth/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/auth/Login.vue"),
  },

  {
    path: "/auth/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/auth/Register.vue"),
  },

  {
    path: "/failed",
    name: "Failed",
    component: () =>
      import(/* webpackChunkName: "Failed" */ "../views/Failed.vue"),
  },

  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "Success" */ "../views/Success.vue"),
  },

  {
    path: "/version",
    name: "version",
    component: () =>
      import(/* webpackChunkName: "Success" */ "../views/Version.vue"),
  },

  {
    path: "/guide-and-help",
    name: "guide-and-help",
    component: () =>
      import(/* webpackChunkName: "Success" */ "../views/GuideAndHelp.vue"),
  },

  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import(/* webpackChunkName: "Success" */ "../views/ContactUs.vue"),
  },
  {
    path: "/select-country",
    name: "CountrySelection",
    component: () =>
      import(
        /* webpackChunkName: "CountrySelection" */ "../views/CountrySelection.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   if(to.path === "/") {
//     console.log(to.query)
//   }
//   next();
// });

export default router;
