<template>
  <div id="app" class="d-flex flex-column min-vh-100 bg">
    <Navbar2 v-if="!isLoading" />
    <router-view v-if="!isLoading" />
    <Footer v-if="!isLoading" />
  </div>
</template>

<script>
import Navbar2 from "@/components/Navbar2.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Navbar2,
    Footer,
  },

  data() {
    return {
      countries: [],
      category: {},
      countryCode: "",
      isLoading: true,
    };
  },

  methods: {
    async getContent() {
      await this.$api
        .get("/Country")
        .then((response) => {
          const res = response.data;
          // this.contents = res;
          this.countries = res;
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
          this.loadingBtn = false;
        });
    },

    async getCountry() {
      await axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          // this.country = data.country_name;
          // this.ip = data.ip;
          this.countryCode = data.country_code;
          console.log(data.country_code);

          const s = this.countries.filter((obj) => {
            return obj.code == data.country_code;
          });

          if (s.length > 0) {
            this.selectCountry(s[0]);
            return;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectCountry(item) {
      localStorage.setItem("country_code", item.code);
      window.location = item.domain;
      return;
    },
  },

  created() {
    this.getContent();
    this.getCountry();
  },

  mounted() {
    // document.addEventListener(
    //   "contextmenu",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   false
    // );
  },
};
</script>
<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
/* 
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
