import Vue from "vue";
import Vuex from "vuex";
import SharedModules from "./modules/SharedModules";
import AuthModule from "./modules/AuthModule";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SharedModules,
    AuthModule,
  },
  plugins: [createPersistedState()],
});
