import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { API, PAYMENT, LOCATION_API } from "../http-common";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "jquery/src/jquery.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import VueTheMask from "vue-the-mask";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "@/assets/css/styles.scss";

// import VueGlide from 'vue-glide-js'

// Vue.use(VueGlide)
import AOS from "aos";
import "aos/dist/aos.css";

import "video.js/dist/video-js.css";
Vue.use(VueTheMask, {
  masked: false,
});
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["stream"];
Vue.use({
  install(Vue) {
    Vue.prototype.$api = API;
    Vue.prototype.$payment = PAYMENT;
    Vue.prototype.$location = LOCATION_API;
  },
});
Vue.use(VueSweetalert2);

library.add(faUserSecret);
library.add(fab);
Vue.component("fa-icon", FontAwesomeIcon);
Vue.use(VueLoading);

new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
