import axios from "axios";

const _username = "Z3d3G@m33s$001";
const _password = "NNM0n3pz3D2022";

export const API = axios.create({
  baseURL: `https://zed.ph/BJ_API/api`,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: _username,
    password: _password,
  },
});

export const PAYMENT = axios.create({
  baseURL: `https://payment.zed.ph/api`,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "QetoGmjtZIeQOFtLX5/3shosRaGUG7+en2Fh4OxsHqH89hTo0DWMmgeLdxNDsEwfwPxKeU4TeQ==",
  },
});

export const LOCATION_API = axios.create({
  baseURL: "http://52.220.32.14:10210/api",
  headers: {
    "Content-Type": "application/json",
  },
});
