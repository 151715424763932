<template>
  <nav
    class="navbar navbar-expand-md fixed-top"
    id="nav"
    v-if="currentRouteName === 'Index' && !isMobile"
  >
    <div class="container">
      <a href="#" class="navbar-brand">
        <img src="../assets/logo.png" alt="logo" height="100" />
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    goToSection(id) {
      var elmntToView = document.getElementById(id);
      elmntToView.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    logout() {
      this.$store
        .dispatch("ADD_AUTH", {})
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMobile() {
      if (screen.width <= 760) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },

  mounted() {
    this.getMobile();
    if (this.isMobile) return;
    window.onscroll = function () {
      var top =
        document.body.scrollTop + document.documentElement.scrollTop == 0;

      document.getElementById("nav").style.display = top ? "block" : "none";
      // document.getElementById("scollbar").style.display = top
      //   ? "none"
      //   : "block";
    };
  },

  computed: {
    getUser() {
      const user = this.$store.getters.GET_AUTH;
      const isEmpty = Object.keys(user).length === 0;

      return {
        isEmpty: isEmpty,
        user: user,
      };
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.navbar-collapse {
  z-index: 2;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

li.nav-item {
  padding: 0px 20px;
  text-transform: uppercase;
  border: 1px solid transparent;
}

li.nav-item:hover {
  border: 1px solid #fff;
  border-radius: 50rem !important;
}
</style>
