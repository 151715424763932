export default {
  state: {
    auth: {},
  },

  mutations: {
    SET_AUTH(state, payload) {
      state.auth = payload;
    },
  },

  actions: {
    ADD_AUTH: ({ commit }, payload) => {
      commit("SET_AUTH", payload);
    },
  },

  getters: {
    GET_AUTH: (state) => state.auth,
  },
};
