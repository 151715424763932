export default {
  state: {
    selectedVideo: {},
    clickId: "",
  },

  mutations: {
    SET_SELECTEDVIDEOS(state, payload) {
      state.selectedVideo = payload;
    },

    SET_CLICKID(state, payload) {
      state.clickId = payload;
    },
  },

  actions: {
    ADD_CLICKID: ({ commit }, payload) => {
      commit("SET_CLICKID", payload);
    },

    ADD_SELECTEDVIDEOS({ commit }, payload) {
      commit("SET_SELECTEDVIDEOS", payload);
    },
  },

  getters: {
    GET_SELECTEDVIDEOS: (state) => state.selectedVideo,
    GET_CLICKID: (state) => state.clickId,
  },
};
